<template>
  <v-container class="pa-0" fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card-title>
          <v-container fluid>
            <v-toolbar flat>
              <v-toolbar-title>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                  <h2 class="textPrimary">
                    {{ $route.meta.title }}
                  </h2>
                </v-col>
              </v-toolbar-title>
              <v-spacer />
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field
                  v-model="search"
                  class="mt-6"
                  prepend-icon="mdi-magnify"
                  label="Buscar"
                  dense
                  hide-details
                  @keyup.enter="submit"
                  clearable
                  @click:clear="onClearClicked"
                />
              </v-col>
            </v-toolbar>
          </v-container>
        </v-card-title>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    search: "",
  }),
  mounted() {
    // console.log(this.$route);
  },
  methods: {
    onClearClicked() {
      if (this.$route.query.text) {
        let query = { ...this.$route.query };
        delete query.text;
        this.$router.replace({ query });
      }
    },
    submit() {
      const query = { ...this.$route.query, text: this.search };
      this.$router.replace({ query });
    },
  },
};
</script>

<style scoped>
</style>