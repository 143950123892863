<template>
  <v-navigation-drawer
    v-model="selectedItem"
    style="background: #ecfbe5 !important"
    app
    clipped
    width="260"
    class="navScroll"
  >
    <template>
      <div class="ma-8">
        <v-img :aspect-ratio="15 / 5" src="@/assets/logo.png" />
      </div>
      <v-list>
        <v-list-item>
          <v-list-item-avatar
            style="margin-left: 90px"
            width="60"
            height="60"
            class="mb-2"
          >
            <v-img
              v-if="userSession.photo_url"
              alt=""
              :src="userSession.photo_url"
            />

            <v-img v-else src="@/assets/images/person.jpg" alt="" />
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="mb-12">
            <v-list-item-title class="text-h6 text-center">
              {{ userSession.fullname }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-center">
              {{ userSession.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-list>
      <v-list-item
        v-for="item in validRoutes"
        :key="item.title"
        link
        :to="item.to"
        :style="item.color ? `background:${item.color};color:inherit` : ''"
      >
        <v-list-item-content>
          <v-list-item-title class="text-center">
            <p
              class="title-route"
              :style="item.color ? 'color:#ffff;' : 'color:#90A4AE;'"
            >
              {{ item.title }}
            </p>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <br /><br /><br /><br />
      <span class="span-logout" @click="logOut()">Cerrar sesión</span>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    // drawer: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters(["userSession"]),
  },
  name: "Sidebar",
  components: {},
  data: () => ({
    selectedItem: 1,
    validRoutes: [],
    // isSelectedChild: this.drawer,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        color: "",
        to: "/",
        id: "home",
        roles: ["admin"],
      },
      {
        title: "Socios",
        icon: "mdi-image",
        color: "",
        to: "/socios?status=1",
        id: "socio",
        roles: ["admin"],
      },
      {
        title: "Invitados",
        icon: "mdi-image",
        color: "",
        id: "invitados",
        to: { name: "invitados", query: { type: "pendientes" } },
        roles: ["admin"],
      },
      {
        title: "Membresias",
        icon: "mdi-image",
        color: "",
        to: "/membresias",
        id: "membresia",
        roles: ["admin"],
      },
      {
        title: "Recepción",
        icon: "mdi-image",
        color: "",
        id: "recepcion",
        to: { name: "recepcion" },
        roles: ["admin", "recepcionista"],
      },
      {
        title: "Reportes",
        icon: "mdi-image",
        color: "",
        id: "reportes",
        to: "/reportes/socios-activos",
        roles: ["admin"],
      },
      {
        title: "Usuarios",
        icon: "mdi-image",
        color: "",
        to: "/usuarios",
        id: "usuarios",
        roles: ["admin"],
      },
    ],
    right: null,
  }),
  watch: {
    $route(val) {
      this.setActiveRoute();
    },
    // drawer (val) {recepcionista-moquegua@email.com
    //   this.isSelectedChild = val
    // },
    // isSelectedChild (val) {
    //   this.$emit('chageDrawer', val)
    // }
  },
  methods: {
    ...mapActions(["logout"]),
    setActiveRoute() {
      let iroute = this.$route;
      this.validRoutes.forEach((element) => {
        if (element.id === iroute.meta.id) {
          element.color = "#479b25";
        } else {
          element.color = "";
        }
      });
    },
    logOut() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
    byRole(role) {
      return function (value) {
        if (value && value.roles) {
          return value.roles.includes(role);
        }
        return false;
      };
    },
  },
  created() {
    this.validRoutes = this.items.filter(this.byRole(this.userSession.role));
    this.setActiveRoute();
  },
};
</script>

<style scoped>
.v-list-item--active {
  background-color: #479b25;
  /* color: white !important; */
}
.v-list-item--active p {
  color: white !important;
}

.title-route {
  font-family: arial;
  font-size: 18px;
  margin: 10px !important;
  color: #707070;
}

.span-logout {
  cursor: pointer;
  color: rgb(144, 164, 174);
  font-family: arial;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
