<template>
  <div>
    <Sidebar />
    <v-main>
      <Header />
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import Sidebar from "@/components/layout/SideBar.vue";
import Header from "@/components/layout/Header.vue";
export default {
  name: "home",
  components: {
    Sidebar,
    Header,
  },
  computed: {
  },
};
</script>

<style>
</style>